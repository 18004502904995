export default [
  {
    path: '/product/list',
    name: 'apps-products-list',
    component: () => import('@/views/pages/products/products-list/ProductsList.vue'),
    meta: {
      pageTitle: 'Producten',
      action: 'read',
      resource: 'product',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'apps-products-edit',
    component: () => import('@/views/pages/products/products-edit/ProductsEdit.vue'),
    meta: {
      pageTitle: 'Product aanpassen',
      action: 'update',
      resource: 'product',
    },
  },
  {
    path: '/product/category',
    name: 'app-category-list',
    component: () => import('@/views/pages/products/category/category-list/CategoryList.vue'),
    meta: {
      pageTitle: 'Productcategorien',
      action: 'read',
      resource: 'category',
    },
  },
  {
    path: '/product/category/edit/:id',
    name: 'app-category-list-edit',
    component: () => import('@/views/pages/products/category/category-edit/CategoryEdit.vue'),
    meta: {
      pageTitle: 'Productcategorie',
      action: 'update',
      resource: 'category',
    },
  },
]
