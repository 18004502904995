export default [
  {
    path: '/commission/overview',
    name: 'app-commission-plan',
    component: () => import('@/views/pages/commission-plan/commission-plan-list/CommissionPlanList.vue'),
    meta: {
      pageTitle: 'Commissie plannen',
      action: 'read',
      resource: 'commission-plan',
    },
  },
  {
    path: '/commission/edit/:id',
    name: 'app-commission-plan-edit',
    component: () => import('@/views/pages/commission-plan/commission-plan-edit/CommissionPlanEdit.vue'),
    meta: {
      pageTitle: 'Commisieplan aanpassen',
      action: 'update',
      resource: 'commission-plan',
    },
  },
  {
    path: '/factuur/overview',
    name: 'app-invoices',
    component: () => import('@/views/pages/finance/finance-list/FinanceList.vue'),
    meta: {
      pageTitle: 'Facturen',
      action: 'read',
      resource: 'invoice',
    },
  },
  {
    path: '/receipt/overview',
    name: 'app-receipts',
    component: () => import('@/views/pages/receipt/receipt-list/ReceiptList.vue'),
    meta: {
      pageTitle: 'Kwitanties',
      action: 'read',
      resource: 'receipt',
    },
  },
]
