export default [
  // *===============================================---*
  // *--------- RELATIONS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/relations/list',
    name: 'apps-relations-list',
    component: () => import('@/views/pages/relations/relations-list/RelationsList.vue'),
    meta: {
      pageTitle: 'Relaties',
      resource: 'relation',
      action: 'read',
    },
  },
  {
    path: '/apps/relations/add',
    name: 'apps-relations-add',
    component: () => import('@/views/pages/relations/relations-add/RelationsAddNew.vue'),
    meta: {
      pageTitle: 'Relaties',
      resource: 'relation',
      action: 'create',
    },
  },
  {
    path: '/apps/relations/edit/:id',
    name: 'apps-relations-edit',
    component: () => import('@/views/pages/relations/relations-edit/RelationsEdit.vue'),
    meta: {
      pageTitle: 'Relatie',
      resource: 'relation',
      action: 'read',
    },
  },
]
