export default [
  {
    path: '/dashboard/overview/operationeel',
    name: 'app-dashboard',
    component: () => import('@/views/pages/dashboard/DashboardOverviewOperational.vue'),
    meta: {
      action: 'read',
      resource: 'dashboard',
    },
  },
  {
    path: '/dashboard/overview/financieel',
    name: 'app-dashboard-overview-financial',
    component: () => import('@/views/pages/dashboard/DashboardOverviewFinancial.vue'),
    meta: {
      action: 'read',
      resource: 'dashboard-finance',
    },
  },
  {
    path: '/dashboard/specificatie/financieel',
    name: 'app-dashboard-specification-financial',
    component: () => import('@/views/pages/dashboard/DashboardSpecificationFinancial.vue'),
    meta: {
      action: 'read',
      resource: 'dashboard-finance',
    },
  },
]
