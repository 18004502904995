export default [
  {
    path: '/order/overview/:id?',
    name: 'app-order',
    component: () => import('@/views/pages/order/order-list/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      action: 'read',
      resource: 'order',
    },
  },
  {
    path: '/order/detail/:id',
    name: 'app-order-detail',
    component: () => import('@/views/pages/order/order-detail/OrderDetail.vue'),
    meta: {
      pageTitle: 'Order details',
      action: 'read',
      resource: 'order',
    },
  },
  {
    path: '/product/calculator',
    name: 'app-premium-calculator',
    component: () => import('@/views/pages/products/premium-calculator/PremiumCalculator.vue'),
    meta: {
      pageTitle: 'Premiecalculator',
      action: 'read',
      resource: 'calculator',
    },
  },
  {
    path: '/apps/order/checkout',
    name: 'apps-order-flow-checkout',
    component: () => import('@/views/pages/order-flow/OrderFlow.vue'),
    meta: {
      action: 'create',
      resource: 'order',
      pageTitle: 'Offerte',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Offerte',
        },
        {
          text: 'Nieuw',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order/prolongaties/:id?',
    name: 'app-prolongations',
    component: () => import('@/views/pages/prolongations/prolongations-list/ProlongationsList.vue'),
    meta: {
      pageTitle: 'Prolongaties',
      action: 'read',
      resource: 'prolongatie',
    },
  },
  {
    path: '/order/royementen',
    name: 'app-disbarments',
    component: () => import('@/views/pages/disbarment/disbarment-list/DisbarmentList.vue'),
    meta: {
      pageTitle: 'Royement aanvragen',
      action: 'read',
      resource: 'royementen',
    },
  },
  {
    path: '/order/claims',
    name: 'app-claim',
    component: () => import('@/views/pages/claim/claim-list/ClaimList.vue'),
    meta: {
      pageTitle: 'Claims',
      action: 'read',
      resource: 'claim',
    },
  },
  {
    path: '/order/collectives',
    name: 'app-collectives-list',
    component: () => import('@/views/pages/collectives/collectives-list/CollectivesList.vue'),
    meta: {
      pageTitle: 'Collectief',
      action: 'read',
      resource: 'collective',
    },
  },
  {
    path: '/order/collectives/:id',
    name: 'app-collectives-edit',
    component: () => import('@/views/pages/collectives/collectives-edit/CollectivesEdit.vue'),
    meta: {
      pageTitle: 'Details Collectief',
      action: 'update',
      resource: 'collective',
    },
  },
]
